<template>
  <div class="info-card-page-list">
    <Action-panel :isReady="isPageReady">
      <Button-base
        :type="2"
        :text="translations.labels.button_add_infoCardPage"
        :hoverInfo="translations.labels.button_add_infoCardPage_hover"
        :onClick="newItem"
      />
    </Action-panel>

    <TableList
      :is-ready="isPageReady"
      :headers="headers"
      :items="model.filter(l => true)"
      :items-per-page="-1"
      sortable
      hideFooter
      @sorted="saveOrder"
      item-key="id"
      :searchVisible="false"
       />
  </div>
</template>

<script>
import Component, { mixins } from "vue-class-component";
import Container from "@/components/dom/Container";
import ActionPanel from "@/components/panel/ActionPanel";
import InnerPanel from "@/components/panel/InnerPanel";
import TablePanel from "@/components/panel/TablePanel";
import ButtonBase from "@/components/dom/ButtonBase";
import SvgIcon from "@/components/dom/SvgIcon";
import Round from "@/components/dom/Round";
import TableList from "@/components/table/TableList";
import Translations from "@/mixins/translations";

@Component({
   components: {
    ActionPanel,
    InnerPanel,
    TablePanel,
    ButtonBase,
    SvgIcon,
    Round,
    Container,
    TableList
  },
  inject: {
    infoCardPageService: "infoCardPageService",
    eventService: "eventService",
    toastService: "toastService"
  }
})
export default class InfoCardPageList extends mixins(Translations) {
  isPageReady = false;
  model = [];
  events = [];
  search = "";

  async prepareList () {
    let infoCardPages = await this.infoCardPageService.list();
    infoCardPages.forEach(icp => {
      const event = this.events.filter(e => e.id == icp.idEvent);
      if (event.length == 0) {
        icp.idEvent = null;
      } else {
        icp.idEvent = event[0].id;
      }
    });
    return infoCardPages;
  }

  async init () {
    try {
      this.events = await this.eventService.list();
      this.model = await this.prepareList();
      this.isPageReady = true;
    } catch (error) {
      // eslint-disable-next-line no-undef
      toastService.this.error(this.translations.errors.somethingWrong);
    }
  }

  toastSuccessLabel (isEnabled) {
    return isEnabled
      ? this.toastService.success(this.translations.success.infoCardPage_disable)
      : this.toastService.success(this.translations.success.infoCardPage_enable);
  }

  async toggleEnabled(idInfoCardPage, isEnabled) {
    const status = isEnabled ? "disable" : "enable";
    this.isPageReady = false;

    try {
      await this.infoCardPageService.setEnabled(idInfoCardPage, status);
      this.model = await this.prepareList();

      this.toastSuccessLabel(isEnabled);
      this.isPageReady = true;
    } catch (error) {
      this.isPageReady = true;
      // eslint-disable-next-line no-undef
      toastService.this.error(this.translations.errors.somethingWrong);
    }
  }

  newItem() {
    this.$router.push("/info-card-pages/new");
  }

  get headers() {
    return [
      {
        text: this.translations.labels.infoCardPage_table_code,
        value: "code"
      },
       {
        text: this.translations.labels.infoCardPage_table_cards,
        value: "cards",
        type: "link",
        icon: "mdi-presentation-play",
        formatValue: (item) => {
          return `/info-card-pages/${item.id}/cards/list`;
        },
      },
      {
        text: this.translations.labels.table_detail,
        value: "detail",
        type: "link",
        icon: "mdi-feature-search-outline",
        formatValue: (item) => {
          return `/info-card-pages/detail/${item.id}`;
        },
      },
      {
        text: this.translations.labels.table_edit,
        value: "edit",
        type: "link",
        formatValue: (item) => {
          return `/info-card-pages/edit/${item.id}`;
        },
        icon: "mdi-pencil",
        buttonType: 2,
      },
      {
        text: this.translations.labels.table_enable,
        value: "enable",
        type: "switch",
        itemValue: (item) => {
          return item.isEnabled;
        },
        onAction: (item) => {
          this.toggleEnabled(item.id, item.isEnabled);
        },
      }
    ];
  }

  mounted () {
    this.init();
    this.$store.commit(
      "SET_SECTION_TITLE",
      this.translations.pageTitles.infoCardPages_list
    );
  }

  async saveOrder(event) {
    const movedItem = this.model.splice(event.oldIndex, 1);
    this.model.splice(event.newIndex, 0, movedItem[0]);

    let newOrders = [];
    this.model.forEach((m, i) => {
      const index = i + 1;
      this.model[i].priorityOrder = index;
      newOrders.push({
        id: m.id,
        priorityOrder: index
      });
    });

    try {
      await this.infoCardPageService.saveOrder(newOrders);
    } catch (e) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }
}

</script>
<style lang="scss" scoped></style>
